.section{
    width: 100%;
    min-height: 100vh;
    color: #fff;
    font-size: 16px;
    background: rgb(14, 13, 13);
    font-family: SourceHanSansSc;
    padding-bottom: 150px;
}
.top{
    width: 100%;
    height: 300px;
    background-image: url(../assets/caseBg.png);
    background-repeat: no-repeat;
    padding: 60px 0 0 0;
    position: relative;
}
.topFont{
    position: relative;
    top: 70px;
}
.imgTop{
    position: absolute;
    top:-90px;
    left: 50%;
    margin-left: -306px;
}
.title{
    width: 100%;
    font-size: 60px;
    font-weight: 700;
}
.subtitle{
    font-size: 18px;
    font-weight: 300;
    padding-top: 30px;
}


.content {
    width: 100%;
    margin: 0 auto;
}
.cardbox{
    margin-bottom: 16px;
}
.cardItem{
    position: relative;
    width: 100%;
    height: 287px;
    font-size: 20px;
    border-radius: 8px;
    overflow: hidden;
}
.cardImg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.address{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 8px 16px;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    box-sizing: border-box;
    background: rgba(0,0,0,.7);
}
.icon{
    width: 28px;
    height: 28px;
}
