.section {
  width: 100%;
  min-height: 100vh;
  color: #fff;
  font-family: SourceHanSansSc;
}
.top{
  width: 1440px;
  height: 140px;
  margin: 0 auto;
}
.title {
  font-size: 60px;
  text-align: left;
}

.content {
  position: sticky;
  top: 180px;
  height: calc(100vh - 300px);
}

.imgsBox {
  width: 100%;
  align-items: center;
  padding-bottom: inherit;
  display: flex;
  justify-content: flex-start;
  gap: 40px;
}

.img {
  width: 764px;
  height: 649px;
  object-fit: fill;
}

.imgTitle {
  font-size: 40px;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: 0em;
  text-align: left;
}

.part3 {
  pointer-events: none;
  margin-top: calc(804px * 3); // 图片宽度 764 + 间距40px
  height: 27vw;
  position: relative;
}

.part3Container {
  width: 1px;
  transform: translate(calc(calc(100 * var(--vw, 1vw)) + 15%));
  height: 100%;
  display: flex;
  align-items: center;
}