.section {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  min-height: 51px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border-bottom: 1px solid #333
}
.title {
  width: 1440px;
  margin: 0 auto;
  font-size: 20px;
  text-align: left;
  color: #fff;
  font-family: SourceHanSansSc;
}




