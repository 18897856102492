.section {
  height: calc(100vh - 53px);
  margin-top: 77px;
  color: #fff;
  background: #000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgBox{
  width: 1920px;
  height: 1080px;
  object-fit: cover;
}
.fontBox{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: #fff;
}
.title{
  font-size: 72px;
  font-weight: 500;
  padding-bottom: 10px;
  font-family: SourceHanSansSc;
  --c: lightseaGary;
  text-shadow: 0 0 10px var(--c),
              0 0 20px var(--c),
              0 0 40px var(--c),
              0 0 80px var(--c);
}
.subtitle{
  font-size: 60px;
  font-weight: 400;
  color: #fff;
  font-family: SourceHanSansSc;
}