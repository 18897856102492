.section{
    width: 100%;
    min-height: 100vh;
    color: #fff;
    font-size: 16px;
    position: relative;
    font-family: SourceHanSansSc;
    padding-bottom: 200px;
}
.top{
    width: 1440px;
    height: 200px;
    margin: 0 auto;
    text-align: left;
    padding: 40px 0 0 200px;
    top: 90px;
    position: -webkit-sticky;
    position: sticky;
    background: #000;
}
.title{
    font-size: 60px;
}

.tabsBox{
    margin: 0 auto;
    color: #ded4d4;
}

.tabItem {
    width: 100%;
    display: flex;
    top: 280px;
    position: -webkit-sticky;
    position: sticky;
    height: calc(100vh - 180px);
    margin-top: 20px;
}
.tabItem:first-child{
    background: linear-gradient(180deg, #182225 0%, #11171A 58.85%, #171E1E 100%);
}

.tabItemActive .tabItemTitle {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
}

.tabItemTitle {
    flex: 440;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 20px 0 0;
    color: #888;
    color: rgba(255, 255, 255, 0.60);   
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 120% */
}
.tabItemImage {
    flex: 1480;
    background: #000;
    position: relative;
}

.tabItemImage img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
}