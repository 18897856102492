.section{
    width: 100%;
    height: 512px;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    text-align: center;
    font-family: SourceHanSansSc;
}
.animateDiv{
    position: relative;
    top: 100px;
}
.title{
    width: 100%;
    margin-top: 60px;
    font-size: 64px;
    font-weight: 700;
}
.title1{
    width: 100%;
    font-size: 64px;
    font-weight: 700;
}
.subtitle{
    width: 770px;
    margin: 20px auto 0;
    font-size: 18px;
    font-weight: 300;
}
.liBox{
    margin-top: 60px;
}
.name{
    width: 240px;
    height: 54px;
    border: none;
    font-size: 20px;
    margin-right: 12px;
    padding: 5px 20px;
    box-sizing: border-box;
}
.phone{
    width: 304px;
    height: 54px;
    border: none;
    font-size: 20px;
    margin-right: 20px;
    padding: 5px 20px;
    box-sizing: border-box;
}
.btn{
    width: 200px;
    height: 64px;
    color: #fff;
    background: #12191C;
    font-size: 24px;
    border: none;
    cursor: pointer;
}
.btn.hover{
    background: #888;
}