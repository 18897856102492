.section{
    width: 100%;
    height:260px;
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    text-align: center;
    background: #222;
}
.content{
    width: 1440px;
    height:200px;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex: 1 0 0;
    text-align: left;
}
.info1{
    font-weight: 500;
}
.info2{
    width: 170px;
    font-weight: 500;
    border-bottom: 1px solid #fff;
    position: relative;
}
.info2::after{
    content: "";
    display: block;
    clear: both;
    position: absolute;
    bottom: -1px;
    width: 26px;
    height: 2px;
    background: #76E3FF;
}
.company{
    margin-top: 30px;
    font-weight: 700;
    // cursor: pointer;
}
.contact{
    width: 445px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
}
.contact > div > img{
    width: 28px;
    height: 28px;
    margin-bottom: 5px;
}
.QRCode{
    width: 100px;
    height: 100px;
}
.address{
    font-size: 12px;
    font-weight: 350;
}
.right{
    font-weight: 350;
    text-align: center;
    font-size: 14px;
}
.address1{
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
