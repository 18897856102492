.section{
    width: 100%;
    min-height: 100vh;
    color: #fff;
    font-size: 16px;
    z-index: 1;
    position: relative;
    padding-bottom: 20px;
    font-family: SourceHanSansSc;
}
.bg{
    width: 100vw;
    height: 100vh;
    // background-image: url("../assets/saasBg.jpeg");
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), 
    url("../assets/saasBg.jpeg") lightgray 0px -98.906px / 100% 118.316% no-repeat;
    position: sticky;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.5;
}
.title{
    margin-top: -100vh;
    width: 100%;
    height: 200px;
    font-size: 60px;
    font-weight: 700;
    text-align: left;
    color: #fff;
    padding: 40px 0 0 200px;
    opacity: 1;
    z-index: 2;
}
.content{
    position: sticky;
    top: 200px;
    height: calc(100vh - 300px);
}
.img{
    height: 100%;
}
.part3 {
    pointer-events: none;
    margin-top: 2500px; // 图片宽度 764 + 间距40px
    height: 27vw;
    position: relative;
}

.part3Container {
    width: 1px;
    transform: translate(calc(calc(100 * var(--vw, 1vw)) + 15%));
    height: 100%;
    display: flex;
    align-items: center;
}