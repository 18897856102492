.section{
    width: 100%;
    min-height: 100vh;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    background: rgb(14, 13, 13);
    font-family: SourceHanSansSc;
}
.title{
    width: 100%;
    height: 200px;
    font-size: 64px;
    text-align: left;
    padding: 150px 0 0 200px;
    font-weight: 700;
}
.liBox{
    width: 1440px;
    margin: 0 auto;
    display: flex;
    flex: 1;
}
.imgItem{
    width: 345px;
    height: 447px;
    margin-right: 20px;
    object-fit: cover;
}
.imgItem:last-child{
    margin-right: 0;
}
.imgItem:hover{
    transform: scale(1.03);
}