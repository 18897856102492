.section{
    width: 100%;
    height: auto;
    color: #fff;
    font-size: 16px;
    font-family: SourceHanSansSc;
}
.bg{
    width: 100vw;
    height: 100vh;
    background-image: url("../assets/cpjsBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 100% 0;
    position: sticky;
    top: 0;
    left: 0;
}
.content{
    margin-top: -100vh;
    position: sticky;
    top: 70px;
    height: calc(100vh - 0px);
}
.top{
    width: 100%;
    text-align: center;
    padding: 50px 0 20px;
    position: relative;
    top: 60px;
}
.title{
    font-size: 60px;
}
.imgBox{
    width:1086px;
    height: 602px;
    margin: 0 auto;
    position: relative;
}
.img{
    width:1086px;
    height: 602px;
    object-fit: fill;
}
.explain{
    position: absolute;
    left: -180px;
    top: 222px;
    width: 183px;
    font-size: 20px;
    text-align: left;
}
.line1{
    transform: rotate(180deg);
    transform-origin: 60px 0;
    position: absolute;
    left: 32px;
    top: 235px;
    width: 0;
    height: 1px;
    background: #fff;
}
.explainTitle{
    font-size: 20px;
    font-weight: 500;
}
.explainContentItem{
    font-size: 16px;
    font-weight: 400;
}
.explain2{
    position: absolute;
    bottom: -80px;
    left: 380px;
    width: 183px;
    font-size: 20px;
    text-align: left;
}
.line2{
    position: absolute;
    top: 380px;
    left: 390px;
    width: 1px;
    height: 0;
    background: #fff;
}
.explain3{
    position: absolute;
    bottom: -140px;
    right: 40px;
    width: 250px;
    font-size: 20px;
    text-align: left;
}
.line3{
    position: absolute;
    top: 440px;
    right: 240px;
    width: 1px;
    height: 10px;
    background: #fff;
}
.explain4{
    position: absolute;
    bottom: 98px;
    right: -390px;
    width: 250px;
    font-size: 20px;
    text-align: left;
}
.line4{
    position: absolute;
    bottom: 180px;
    left: 1000px;
    height: 1px;
    background: #fff;
}

.part3 {
    pointer-events: none;
    margin-top: calc(100vh + 1000px);
    height: 500px;
    position: relative;
}
.part3Container {
    width: 1px;
    transform: translate(calc(calc(100 * var(--vw, 1vw)) + 15%));
    height: 100%;
    display: flex;
    align-items: center;
}