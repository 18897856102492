.secondSection{
    width: 1440px;
    // height: calc(100vh - 77px);
    height: 100vh;
    margin: 77px auto 0;
    color: #fff;
    font-family: SourceHanSansSc;
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-start;
}
.left{
    width: 100%;
    height: 615px;
    text-align: left;
    position: relative;
    left:-100px;
}
.title{
    font-size: 120px;
    font-weight: 500;
}
.fontImg{
    width: 600px;
}
.introduce{
    font-size: 58px;
    font-weight:400;
}
.introduce1{
    padding-top: 50px;
    font-weight:700;
    font-size: 58px;
}
.content{
    left: 840px;
    top: 180px;
    position: relative;
    width: 660px;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    transform: translateX(0%);
    opacity: 0;
}

