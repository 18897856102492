.section{
    width: 100%;
    min-height: 100vh;
    color: #fff;
    font-size: 16px;
    padding-bottom: 80px;
    font-family: SourceHanSansSc;
}
.title{
    width: 1440px;
    margin: 0 auto;
    font-size: 60px;
    text-align: left;
    padding: 100px 0 0;
}
.content{
    // width: 1440px;
    width: 100%;
    height: 500px;
    margin: 0 auto;
}
.cardBox{
    width: 1440px;
    height: 600px;
    color: #fff;
    display: flex;
}
.cardItem{
    width: 439px;
    height: 600px;
    border-radius: 16px;
    border: 0.5px solid rgba(255, 255, 255, 0.00);
    opacity: 0.9;
    background: linear-gradient(180deg, #182225 0%, #11171A 58.85%, #171E1E 100%);
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.20);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imgContainer{
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.name{
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
}
